import { render, staticRenderFns } from "./InsAddComments.vue?vue&type=template&id=fb0767bc&scoped=true&"
import script from "./InsAddComments.vue?vue&type=script&lang=ts&"
export * from "./InsAddComments.vue?vue&type=script&lang=ts&"
import style0 from "./InsAddComments.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsAddComments.vue?vue&type=style&index=1&id=fb0767bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb0767bc",
  null
  
)

export default component.exports