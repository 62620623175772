



























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import PaymentMethod from '@/model/payment';
@Component
export default class InsPayment extends Vue {
    private radio:number = 0;
    private payments:PaymentMethod[] = [];
    @Prop({ default: false }) private cleanUpdate:boolean | undefined;
    @Prop() private lockPayment:boolean | undefined;
    created () {
      // if (this.lockPayment) return;
      if (!this.cleanUpdate) { this.$store.dispatch('setPaymentMethods', this.$Api.checkout.getPaymentMethod()); }
      // this.$store.dispatch('setPaymentMethods', this.$Api.checkout.getPaymentMethod());
      this.$store.state.paymentMethods.then(result => {
        this.payments = result.Payment;
        if (result.Payment && result.Payment.length > 0) this.$store.dispatch('setPaymentMethod', this.payments[0]);
      });
    }
    choisePaymentMethod (index) {
      this.radio = index;
      this.$store.dispatch('setPaymentIndex', index);
    }
    // get save () {
    //   if (this.$store.state.savePaymentMethod === true && this.payments.length > 0) {
    //     this.$Api.paymentApi.savePayMethod(this.payments[this.$store.state.paymentIndex].Id).then((result) => {
    //       this.$store.dispatch('setPaymentMethod', this.payments[this.$store.state.paymentIndex]);
    //     });
    //     this.$store.dispatch('setSavePaymentMethod', false);
    //   }
    //   return this.$store.state.savePaymentMethod;
    // }
    get paymentMethod () {
      return this.$store.state.paymentMethod;
    }
    // beforeUpdate () {
    //   if (this.lockPayment) return;
    //   if (!this.cleanUpdate) { this.$store.dispatch('setPaymentMethods', this.$Api.checkout.getPaymentMethod()); }
    //   this.$store.state.paymentMethods.then(result => {
    //     this.payments = result.Payment;
    //     if (result.Payment && result.Payment.length > 0) this.$store.dispatch('setPaymentMethod', this.payments[0]);
    //   });
    // }
}
