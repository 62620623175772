
























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Coupon from '@/model/coupon';
import PromotionDiscount from '@/model/PromotionDiscount';
@Component
export default class InsConcessions extends Vue {
    private totalAmount:number = 0;
    private total:number = 0;
    private promotionCode = '';
    private coupon:Coupon[] = [new Coupon()];
    private multiple:Coupon[] = [];
    private single:Coupon[] = [];
    private selected = {};
    private all = {};
    private current: string = '';
    private currentPrice: number = 0;
    private delete1: any = [];
    @Prop() private lockConcessions!:boolean;
    private ll: boolean = false;
    private exp: number = 0;
    private showDistcount :boolean = false;
    created () {
      this.$set(this.selected, 'length', 0);
      this.$store.state.shopCart.then((result) => {
        this.totalAmount = result.ShopCart.TotalAmount;
        this.currentPrice = this.totalP;
      }).then(() => {
        this.$Api.member.getActiveCoupon({ Page: 1, PageSize: 10 }).then((result) => {
          this.coupon = result.Coupon;
          this.coupon.forEach((element) => {
            if (element.MeetAmount >= this.totalP) return;
            element.canCheck = false;
            this.$set(this.all, element.Id, element);
            if (element.IsAdditional === true) this.multiple.push(element);
            else this.single.push(element);
          });
        });
      });
    }
    @Watch('promotionCode')
    onPromotionCode () {
      if (!this.promotionCode) {
        this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
        this.$emit('promotionCode', '');
        this.showDistcount = false;
      }
    }
    get express () {
      return this.$store.state.pickUpExpress ? this.$store.state.expressPoint : this.$store.state.express;
    }
    searchCoupon () {
      if (this.delete1.length !== 0) { this.$Confirm(this.$t('Message.Message'), this.$t('Concessions.Cannot')); return; }
      this.$Api.order.getPromotionCodeFrontView(this.promotionCode).then((result) => {
        this.$store.dispatch('setPromotionDiscount', result.PromotionDiscount);
        this.$emit('promotionCode', this.promotionCode);
        this.showDistcount = true;
      }).catch((error) => {
        console.log(error);
        this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.promotionCodeError'));
      });
    }
    get discount () {
      return this.$store.state.discount;
    }
    checkChange (item) {
    }
    @Watch('delete1', { deep: true })
    onSelectedChange (n, old) {
      this.$emit('coupons', this.delete1);
      if (n.length === 0) {
        this.single.forEach(element => {
          element.canCheck = false;
        });
        this.multiple.forEach(element => {
          element.canCheck = false;
        });
        if (this.ll) { this.ll = false; return; }
      }
      // let current = this.delete1[this.delete1.length - 1];
      // if (old.length === 0 && n.length === 1) {
      //   if (this.all[current].IsAdditional === true) {
      //     this.single.forEach(element => {
      //       if (this.all[current].Id !== element.Id) { element.canCheck = true; }
      //     });
      //   } else {
      //     this.single.forEach(element => {
      //       if (this.all[current].Id !== element.Id) { element.canCheck = true; }
      //     });
      //     this.multiple.forEach(element => {
      //       element.canCheck = true;
      //     });
      //   }
      // }
      // if (n.length > old.length) {
      //   let c = this.all[current];
      //   if (c.IsDiscount) {
      //     this.currentPrice -= this.totalP * (c.DiscountAmount / 100);
      //   } else {
      //     this.currentPrice = this.currentPrice - c.DiscountAmount;
      //   }
      // } else {
      //   let c = this.all[old[old.length - 1]];
      //   if (c.IsDiscount) {
      //     this.currentPrice += this.totalP * (c.DiscountAmount / 100);
      //   } else {
      //     this.currentPrice = this.currentPrice + c.DiscountAmount;
      //   }
      // }
      let current = this.delete1[this.delete1.length - 1];
      if (old.length === 0 && n.length === 1) {
        if (this.all[current].IsAdditional === true) {
          this.single.forEach(element => {
            if (this.all[current].Id !== element.Id) { element.canCheck = true; }
          });
        } else {
          this.single.forEach(element => {
            if (this.all[current].Id !== element.Id) { element.canCheck = true; }
          });
          this.multiple.forEach(element => {
            element.canCheck = true;
          });
        }
      }
      // 計算多個優惠
      let mult:any[] = [];
      let temp = this.totalP;
      this.currentPrice = this.totalP;
      this.delete1.forEach(element => {
        if (this.all[element].IsDiscount) mult.push(this.all[element]);
        else {
          temp = this.currentPrice - this.all[element].DiscountAmount;
        }
      });
      mult.forEach((element) => {
        temp -= this.currentPrice * (element.DiscountAmount / 100);
      });
      this.currentPrice = temp;
      if (old.length === 0 && n.length === 0) this.currentPrice = this.totalP;
    }
    get cp () {
      return (this.discount.Id === '-1'
        ? this.currentPrice
        : (this.discount.IsDiscount ? ((this.currentPrice) * (1 - this.discount.Favorable / 100)).toFixed(2) : (this.currentPrice) - this.discount.Favorable));
    }
    get totalP () {
      // if (this.delete1.length === 1) { this.ll = true; } + Number(this.$store.state.express.DiscountPrice as string) + this.$store.state.express.DiscountPrice
      this.currentPrice = this.totalAmount;
      // if (this.delete1.length !== 0) this.delete1 = []; + Number(this.$store.state.express.DiscountPrice as string)
      return this.totalAmount;
    }
    get exdp () {
      this.exp = this.$store.state.express.DiscountPrice;
      return Number(this.$store.state.express.DiscountPrice as string);
    }
    @Watch('exp')
    onExpChange () {
      // this.ll = true;
      if (this.delete1.length !== 0) { this.delete1 = []; };
    }
    cancel () {
      while (this.delete1.length !== 0) { this.ll = true; this.delete1.pop(); }
      if (this.promotionCode) this.promotionCode = '';
    }
}
