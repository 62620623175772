import { render, staticRenderFns } from "./InsYouWouldLike2.vue?vue&type=template&id=65e23c1c&scoped=true&"
import script from "./InsYouWouldLike2.vue?vue&type=script&lang=ts&"
export * from "./InsYouWouldLike2.vue?vue&type=script&lang=ts&"
import style0 from "./InsYouWouldLike2.vue?vue&type=style&index=0&id=65e23c1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e23c1c",
  null
  
)

export default component.exports