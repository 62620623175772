






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    NewsNav: () => import('@/components/business/pc/news/InsNewsNav.vue'),
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    Location: () => import('@/components/base/pc/InsLocation.vue')
  }
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content:any[]=[];
  created () {
    try {
      let seoData = require('../../../../sdk/common/SeoData');
      let keywords = document.createElement('meta');
      keywords.setAttribute('name', 'keywords');
      keywords.setAttribute('content', seoData.seoItem['cms' + this.id].keyword);
      document.head.appendChild(keywords);
      let description = document.createElement('meta');
      description.setAttribute('name', 'description');
      description.setAttribute('content', seoData.seoItem['cms' + this.id].description);
      document.head.appendChild(description);
      document.title = seoData.seoItem['cms' + this.id].title;
      document.dispatchEvent(new Event('render-event'));
    } catch (e) {
      console.log('當前頁面無需SEO。');
    }
    let id = this.$route.params.id as string;
    let that = this;
    this.$Api.cms.getContent(id).then((result) => {
      this.content = result;
      this.$nextTick(() => {
        if (result.Title) document.title = result.Desc;
      });
      this.CateName = result.Category.Name;
      this.$HiddenLayer();
    });
  }
  mounted () {

  }
  @Watch('$route', { deep: true })
  onRouteChange () {
    let id = this.$route.params.id as string;
    this.$Api.cms.getContent(id).then((result) => {
      this.content = result;
      this.$nextTick(() => {
        if (result.Title) document.title = result.Desc;
      });
      this.CateName = result.Category.Name;
      this.$HiddenLayer();
    });
  }
  get id () {
    return this.$route.params.id;
  }
}
