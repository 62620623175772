import { render, staticRenderFns } from "./InsPayment.vue?vue&type=template&id=62df1985&scoped=true&"
import script from "./InsPayment.vue?vue&type=script&lang=ts&"
export * from "./InsPayment.vue?vue&type=script&lang=ts&"
import style0 from "./InsPayment.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsPayment.vue?vue&type=style&index=1&id=62df1985&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62df1985",
  null
  
)

export default component.exports