











import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inProductCat from '@/components/business/mobile/product/InsProductCat.vue';
import YouWouldLike from '@/model/youWouldLike';
import Catalogs from '@/model/Catalogs';
@Component({ components: { inProductCat } })
export default class InsProductCat extends Vue {
  // private catalogs: Catalogs[] = [];
  private catalogs = {};
  private callCatalogs!:Promise<any>;
  created () {
    this.$HiddenLayer();
    this.callCatalogs = this.$Api.product.getAttrList2();
    let c = {};
    this.callCatalogs.then((result) => {
      console.log(result);
      result.Catalogs.forEach(element => {
        c[element.Id] = element.Name;
        element.Children.forEach(ce => {
          c[ce.Id] = ce.Name;
          ce.Children.forEach(ge => {
            c[ge.Id] = ge.Name;
          });
        });
      });
      this.catalogs = c;
      if (!this.$route.params.catId) this.$store.dispatch('setCatId', this.catalogs[0].Id);
    });
  }
  selectCatalogs (e) {
    let target = e.target as HTMLElement;
    if (target.className === 'cat_item') {
      this.$router.push({
        name: 'productCat',
        path: '/product/cat',
        params: {
          catId: target.dataset.keys as string
        }
      });
    }
  }
  get catId () {
    return String(this.$route.params.catId);
  }
  get cname () {
    return this.catalogs[this.catId];
  }
}
