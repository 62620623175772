










import { Vue, Prop, Component } from 'vue-property-decorator';
import inAccordion from '@/components/base/mobile/InsAccordion.vue';
import inComments from '@/components/business/mobile/product/InsComments.vue';
@Component({ components: { inAccordion, inComments } })
export default class InsProductDescription extends Vue {
    @Prop() private skuSource!:string;
    @Prop() private tabSource!:string;
    get ProductSku () {
      if (this.skuSource) return this.$store.state[this.skuSource];
      else throw new Error('Edtoys產品詳情組件：要求你設置sku數據源');
    }
    get Tabs () {
      if (this.tabSource) return this.$store.state[this.tabSource];
      else throw new Error('Edtoys產品詳情組件：要求你設置tab數據源');
    }
}
