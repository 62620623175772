










import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class EdProductShare extends Vue {
}
