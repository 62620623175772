
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import InsProductDescription from '@/components/business/pc/product/InsProductDescription.vue';
import inYouWouldLike from '@/components/business/pc/product/InsYouWouldLike.vue';
import YouWouldLike from '@/model/youWouldLike';
import inPanel from '@/components/business/pc/product/InsPanel.vue';
import inPreview from '@/components/business/pc/product/InsPreview.vue';
import inProductUpOrDown from '@/components/business/pc/product/InsProductUpOrDown.vue';
import insBreadcrumb from '@/components/base/pc/InsBreadcrumb.vue';
@Component({ components: {
  inPreview,
  inPanel,
  inYouWouldLike,
  inProductUpOrDown,
  InsProductDescription,
  insBreadcrumb
} })
export default class InsProductDetail extends Vue {
  mounted () {
  }
  created () {
    try {
      let seoData = require('../../sdk/common/SeoData');
      let keywords = document.createElement('meta');
      keywords.setAttribute('name', 'keywords');
      keywords.setAttribute('content', seoData.seoItem['product' + this.pc].keyword);
      document.head.appendChild(keywords);
      let description = document.createElement('meta');
      description.setAttribute('name', 'description');
      description.setAttribute('content', seoData.seoItem['product' + this.pc].description);
      document.head.appendChild(description);
      document.dispatchEvent(new Event('render-event'));
      document.title = seoData.seoItem['product' + this.pc].title;
      this.$store.dispatch('setProductDetailSku', this.pc);
    } catch (e) {
      console.log('當前頁面無需SEO。');
    }
    // 获取产品详情数据
    this.$Api.product.GetProduct(this.pc).then((result) => {
      this.$nextTick(() => {
        document.title = result.PanelDetail.Name;
      });
      this.$store.dispatch('setProductDetail', result.PanelDetail);
      this.$store.dispatch('setImgList', result.AdditionalImage);
      let Tabs = result.Tab;
      Tabs.setTabsName(this.$t('product.comments.title'));
      this.$store.dispatch('setProductDetailTab', Tabs);
      this.$HiddenLayer();
    });
  }
  @Watch('pc')
  onWatchRoute (o, n) {
    console.log(this.pc);
    this.$store.dispatch('setProductDetailSku', this.pc);
    this.$Api.product.GetProduct(this.pc).then((result) => {
      this.$nextTick(() => {
        document.title = result.PanelDetail.Name;
      });
      this.$store.dispatch('setProductDetail', result.PanelDetail);
      this.$store.dispatch('setImgList', result.AdditionalImage);
      let Tabs = result.Tab;
      Tabs.setTabsName(this.$t('product.comments.title'));
      this.$store.dispatch('setProductDetailTab', Tabs);
      this.$HiddenLayer();
    });
  }
  // gotoSlider () {
  //   this.$nextTick(() => {
  //     let target = document.getElementById('tab') as HTMLElement;
  //     target.scrollIntoView();
  //   });
  // }
  get pc () {
    return this.$route.params.id;
  }
}
