import { render, staticRenderFns } from "./InsProductCat.vue?vue&type=template&id=53320e49&scoped=true&"
import script from "./InsProductCat.vue?vue&type=script&lang=ts&"
export * from "./InsProductCat.vue?vue&type=script&lang=ts&"
import style0 from "./InsProductCat.vue?vue&type=style&index=0&id=53320e49&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53320e49",
  null
  
)

export default component.exports