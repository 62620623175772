








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import sdk from '@/sdk/InstoreSdk';
@Component({
  // components: {}
})
export default class InsRegister extends Vue {
  created () {
    this.$HiddenLayer();
  }
  lang:string[] = ['E', 'C', 'S'];
  checkEmail (rule, value, callback) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (!value) {
      callback(new Error(this.$t('Register.RegisterEmail') as string));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('Register.RegisterEmail01') as string));
      }
    }, 100);
  }

  validatePassword (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('Register.RegisterPassword') as string));
    } else {
      if (this.regForm.Password !== '') {
        // this.$refs.ruleForm.validateField('UserConfirmPassword');
      }

      callback();
    }
  }

  validatePassword2 (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('Register.RegisterNewPassword') as string));
    } else if (value !== this.regForm.Password) {
      callback(new Error(this.$t('Register.RegisterNewPassword01') as string));
    } else {
      callback();
    }
  }
  private regForm = {
    Email: '',
    Password: '',
    ConfirmPassword: '',
    FirstName: '',
    LastName: '',
    Mobile: '',
    BirthMD: '',
    Gender: '',
    Language: '',
    type: ''

  };
  get rules () {
    return {
      Email: [
        { required: true, validator: this.checkEmail, trigger: 'blur' }
      ],
      Password: [
        { required: true, validator: this.validatePassword, trigger: 'blur' }
      ],
      ConfirmPassword: [
        { required: true, validator: this.validatePassword2, trigger: 'blur' }
      ],
      FirstName: [
        {
          required: true,
          message: this.$t('Register.RegisterFirstName'),
          trigger: 'blur'
        }
        //  { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      ],
      LastName: [
        {
          required: true,
          message: this.$t('Register.RegisterLastName'),
          trigger: 'blur'
        }
        //  { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      ],
      type: [
        {
          required: true,
          message: this.$t('Register.RegisterAgree_check'),
          trigger: 'blur'
        }
      ]
    };
  }
  private submitForm (formName) {
    let _this = this;

    (this.$refs.regForm as ElForm).validate(valid => {
      if (valid) {
        this.$Api.member.register(this.regForm).then((result) => {
          if (result.Succeeded) {
            sdk.api.member.login(this.regForm.Email, this.regForm.Password, true).then(
              function (response) {
                _this.$store.dispatch('doLogin');
                return _this.$route.query && _this.$route.query.returnurl ? _this.$route.query.returnurl : undefined;
              },
              function (response) {
                _this.$message({
                  message: response.Message,
                  type: 'error'
                });
              }
            ).then(
              (url) => {
                sdk.api.member.getProfile().then(
                  function (data) {
                    if (data) {
                      // _this.regForm = data;
                      _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
                      _this.$i18n.locale = _this.lang[data.Language];
                      _this.$store.dispatch('setLang', _this.lang[data.Language]);
                      _this.$Storage.set('locale', _this.lang[data.Language]);
                      if (url) { _this.$router.push(_this.$route.query.returnurl as string); } else { _this.$router.push('/account/MemberCentral'); }
                    } else {
                      _this.$store.dispatch('Logout');
                    }
                  },
                  function (data) {
                    _this.$message({
                      message: data,
                      type: 'error'
                    });
                  }
                );
              }
            );
          } else {
            this.$message({
              message: result.Message,
              type: 'error'
            });
          }
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
}
