


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue')
  }
})
export default class InsPayFail extends Vue {
  ReturnHomePage () {
    this.$router.push('/');
  }
}
