
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsDialog extends Vue {
    @Prop() private visible!:boolean;
    touch () {
      this.$emit('input', false);
    }
    @Watch('visible')
    onShowChange () {
      if (this.visible === true) { setTimeout(() => { document.body.style.overflowY = 'hidden'; }, 50); } else document.body.style.overflowY = 'auto';
    }
}
