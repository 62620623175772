


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    NewsNav: () => import('@/components/business/mobile/news/InsNewsNav.vue'),
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue'),
    Location: () => import('@/components/base/mobile/InsLocation.vue')
  }
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content: any[] = [];
  created () {
    let id = this.$route.params.id as string;
    let that = this;
    this.$Api.cms.getContentByDevice({ ContentId: id, IsMobile: true }).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    let id = this.$route.params.id as string;
    this.$Api.cms.getContentByDevice({ ContentId: id, IsMobile: true }).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
}
