


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    Location: () => import('@/components/base/pc/InsLocation.vue')
  }
})
export default class insNewsMain extends Vue {
  // storeID: string = this.$store.state.ids;
  currentPage: number = 1;
  pagesize: number = 12;
  newarry: any[] = [];
  dataLength: any = '';
  PageName: string = '';
  current: number = 0;
  data:any[]=[];
  alldata:any[]=[];
  // 列表点击时，把值赋值给路由传到指定的页面
  openContent (id) {
    this.$router.push({
      path: '/cms/Content/',
      name: 'cmsContent',
      params: { id: id }
    });
  }
  currentChange (currentPage) {
    // 改变当前页
    this.currentPage = currentPage;
  }
  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }
  get storeID () {
    return this.$store.state.ids;
  }

  // 获取所有目录的列表
  getAllList () {
    let that = this;
    this.$Api.cms.getSubCatContents({ CatID: 40101 }).then((result) => {
      that.alldata = result.Data;
      if (that.storeID.id === undefined || that.storeID.id === '') {
        that.dataLength = this.alldata.length;
      } else {
        this.newarry = this.data.filter(function (item) {
          return item.CatId === that.storeID.id;
        });
        that.dataLength = that.newarry.length;
      }
    });
  }

  // 获取点击目录的列表
  getCateList () {
    let that = this;
    this.$Api.cms.getContentsByCatId(that.storeID.id, 1, 12).then((result) => {
      this.data = result.Data;
      this.newarry = this.data.filter(function (item) {
        return item.CatId === that.storeID.id;
      });
      if (that.storeID.id === undefined || that.storeID.id === '') {
        that.dataLength = this.data.length;
      } else {
        that.dataLength = that.newarry.length;
      }
    });
  }

  // 获取当传来的storeID为空时，dataLength的长度值
  created () {
    var that = this;
    if (that.storeID.id === undefined) {
      that.getAllList();
    } else {
      that.getCateList();
    }
  }

  // 监听storeID，如果改变ID时，则改变dataLength的长度值
  @Watch('storeID', { deep: true })
  onStoreIDChange (o, n) {
    this.getCateList();
  }
}
