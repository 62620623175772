




















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inButton from '@/components/base/pc/InsButton.vue';
@Component({ components: { inButton } })
export default class ProductUpOrDown extends Vue {
  // @Prop() private readonly catId!:number;
  // @Prop() private readonly sku!:string;
  @Prop() private skuSource!:string;
  @Prop() private catIdSource!:string;
  get sku () {
    if (this.skuSource) return this.$store.state[this.skuSource];
    else throw new Error('產品翻頁組件：要求你設置sku數據源');
  }
  get catId () {
    if (this.catIdSource) return this.$store.state[this.catIdSource].CatId;
    else throw new Error('產品翻頁組件：要求你設置catID數據源');
  }
  up () {
    this.$Api.product.GetProductUpOrDown(this.sku, this.catId, true).then((result) => {
      this.$router.push({ name: 'ProductsDetail', params: { id: result } });
    }).catch(error => {
      console.log(error);
      this.$message(this.$t('product.none') as string);
    });
  }
  down () {
    this.$Api.product.GetProductUpOrDown(this.sku, this.catId, false).then((result) => {
      this.$router.push({ name: 'ProductsDetail', params: { id: result } });
    }).catch(error => {
      console.log(error);
      this.$message(this.$t('product.none') as string);
    });
  }
}
