
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import YouWouldLike from '@/model/youWouldLike';
import InsPage from '@/components/base/pc/InsPage.vue';
@Component({
  components: {
    InsProductList: () => import('@/components/business/pc/product/InsProductList.vue'),
    InsPage
  }
})
export default class InsProductSearch extends Vue {
  proList: YouWouldLike[] = []; // 产品数据
  currentPage: number = 1; // 当前页
  pageSize: number = 20; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数

  attrs: object[] = []; // 选中的产品属性数组
  searchCatalogs: number[] = []; // 选中的产品目录数组
  searchType: number = 1; // 搜索类型（0 => 叠加，1 => 筛选）

  // 搜索关键词
  get searchKey () {
    let a = this.$route.params.key ? this.$route.params.key : '+';
    if (a === '+') {
      return '';
    } else {
      return a;
    }
  }

  // 产品高级搜索
  productSearch (key) {
    this.$Api.product.search({
      Key: key,
      PageInfo: {
        Page: this.currentPage,
        PageSize: this.pageSize
      },
      CatIdS: this.searchCatalogs,
      Attrs: this.attrs,
      Type: this.searchType
    }).then((result) => {
      this.proList = result.YouWouldLike;
      this.totalRecord = result.TotalRecord;
      this.$HiddenLayer();
    });
  }

  handleAttrsChange (val) {
    this.attrs = val;
    this.productSearch(this.searchKey);
  }

  handleCatalogsChange (val) {
    this.searchCatalogs = val;
    this.productSearch(this.searchKey);
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    this.productSearch(this.searchKey);
  }
  created () {
    this.productSearch(this.searchKey);
  }

  @Watch('searchKey', { deep: true })
  onSearchKeyChange () {
    this.productSearch(this.searchKey);
  }
  get key () {
    return this.$store.state.searchKey;
  }
  @Watch('key')
  onKeyChange () {
    this.productSearch(this.$store.state.searchKey);
  }
}
