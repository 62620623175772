
















import YouWouldLike from '@/model/youWouldLike';
import inButton from '@/components/base/mobile/InsButton.vue';
import inProductWindow from '@/components/business/mobile/product/InsProductItem.vue';
import Currency from '@/model/currency';
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component({
  components: {
    inButton, inProductWindow
  }
})
export default class InsYouWouldLike2 extends Vue {
  // data
  private SwiperOption = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 1.5
  };
  private InnerItems: YouWouldLike[] = [];
  private InnerItemsCopy: YouWouldLike[] = [];
  private ShowItems: YouWouldLike[][] = [];
  private Layer: boolean = false;
  //   props
  @Prop() private readonly styla!: string;
  @Prop() private readonly imgStyla!: string;
  @Prop() private readonly title!: string;
  // @Prop() private readonly pageNum!: number;
  // @Prop() private readonly items!: YouWouldLike[];
  // @Prop() private readonly ProductSku!: string;
  @Prop() private readonly skuSource!: string;
  //   method
  click (e: MouseEvent) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
    }
  }
  buttonClick (item: YouWouldLike) {
    console.log(item);
  }
  created () {
    this.$Api.product.getRltProduct(this.ProductSku).then((result) => { this.InnerItems = result.YouWouldLike; });
  }
  get ProductSku () {
    if (this.skuSource) return this.$store.state[this.skuSource];
    else throw new Error('猜你喜歡組件：要求你設置sku數據源');
  }
//   @Watch('InnerItems')
//   onInnerItemsChange (o, n) {
//     this.InnerItemsCopy = this.InnerItems.slice();
//     this.ShowItems.splice(0, this.ShowItems.length);
//     while (this.InnerItemsCopy.length > 0) {
//       this.ShowItems.push(this.InnerItemsCopy.splice(0, this.pageNum));
//     }
//     while (
//       this.ShowItems.length > 0 &&
//       this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
//     ) {
//       this.ShowItems[this.ShowItems.length - 1].push(
//         new YouWouldLike('-1', '', '', '', '', '', new Currency(), true)
//       );
//     }
//   }
//   @Watch('productSku')
//   onProductSkuChange (o, n) {
//     Vue.prototype.$Api.product.getRltProduct(this.ProductSku).then(result => {
//       if (Array.isArray(result.data) && result.data.length > 0) {
//         result.data.foreach((element) => {
//           let list:YouWouldLike[] = [];
//           list.push(new YouWouldLike(element.Sku, element.Img, element.Code, element.ListPrice, element.SalePrice));
//           this.InnerItems = list;
//         });
//       }
//     });
//   }
}
