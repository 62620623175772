import { render, staticRenderFns } from "./InsNum.vue?vue&type=template&id=0384395d&scoped=true&"
import script from "./InsNum.vue?vue&type=script&lang=ts&"
export * from "./InsNum.vue?vue&type=script&lang=ts&"
import style0 from "./InsNum.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsNum.vue?vue&type=style&index=1&id=0384395d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0384395d",
  null
  
)

export default component.exports