





























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
class ImgItem {
  Src:string;
  Virtual:boolean;
  constructor (src:string, virtual:boolean = false) {
    this.Src = src;
    this.Virtual = virtual;
  }
}
@Component
export default class InsPreview extends Vue {
  // data
  private InnerItems: ImgItem[] = [];
  private ShowItems: ImgItem[][] = [];
  private SwiperOption = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };
  private CurrentPic: string = '';
  private ScaleShow: boolean = false;
  private TouchX: number = 0;
  private TouchY: number = 0;
  // props
  @Prop() private imgListSource!:string;
  get imgList () {
    if (this.imgListSource) return this.$store.state[this.imgListSource];
    else throw new Error('產品预览組件：要求你設置imgListSource數據源');
  }
  @Prop() private readonly height!: string;
  @Prop() private readonly width!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly pageNum!: number;
  // computed
  get warpperStyle (): string {
    return 'width:' + this.width + ';height:' + this.height + ';' + this.styla;
  }
  // method
  logPoint (e) {
    e = e as TouchEvent;
    this.TouchX = e.touches['0'].clientX;
    this.TouchY = e.touches['0'].clientY;
  }
  scale (e) {
    this.ScaleShow = true;
    // e = e as TouchEvent;
    // if (Math.abs(this.TouchX - e.changedTouches['0'].clientX) < 20) {
    //   let target = e.target as HTMLElement;
    //   if (target.nodeName === 'IMG') {
    //     this.CurrentPic = target.dataset.key as string;
    //     this.ScaleShow = true;
    //   }
    // }
  }
  created () {
    this.CurrentPic = this.imgList[0] ? this.imgList[0][0] : '';
    this.imgList.forEach(element => {
      this.InnerItems.push(new ImgItem(element, false));
    });
    while (this.InnerItems.length > 0) {
      this.ShowItems.push(this.InnerItems.splice(0, this.pageNum));
    }
    while (
      this.ShowItems.length > 0 &&
      this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
    ) {
      this.ShowItems[this.ShowItems.length - 1].push(
        new ImgItem('', true)
      );
    }
    document.body.addEventListener('touchstart', () => { this.ScaleShow = false; });
  }
  @Watch('imgList', { deep: true })
  imgListChange () {
    this.CurrentPic = this.imgList[0][0];
    this.InnerItems = [];
    this.ShowItems = [];
    this.imgList.forEach(element => {
      this.InnerItems.push(new ImgItem(element));
    });
    while (this.InnerItems.length > 0) {
      this.ShowItems.push(this.InnerItems.splice(0, this.pageNum));
    }
    while (
      this.ShowItems.length > 0 &&
      this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
    ) {
      this.ShowItems[this.ShowItems.length - 1].push(
        new ImgItem('', true)
      );
    }
  }
  click (e) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
      this.CurrentPic = target.dataset.key as string;
    }
  }
  // @Watch('imgList', { deep: true })
  // onImgList () {
  //   this.CurrentPic = this.src;
  //   this.InnerItems = [];
  //   this.ShowItems = [];
  //   this.imgList.forEach(element => {
  //     this.InnerItems.push(new ImgItem(element[0]));
  //   });
  //   while (this.InnerItems.length > 0) {
  //     this.ShowItems.push(this.InnerItems.splice(0, this.pageNum));
  //   }
  //   while (
  //     this.ShowItems.length > 0 &&
  //     this.ShowItems[this.ShowItems.length - 1].length < this.pageNum
  //   ) {
  //     this.ShowItems[this.ShowItems.length - 1].push(
  //       new ImgItem('', true)
  //     );
  //   }
  // }
  mounted () {
  }
  loadError (e) {
    e.target.src = '/static/Image/proddef.jpg';
  }
}
