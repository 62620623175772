




























import { Vue, Prop, Component } from 'vue-property-decorator';
import Catalogs from '@/model/Catalogs';
@Component
export default class EdCatalogs extends Vue {
  private catalogs: Catalogs[] = [];
  private show = {};
  created () {
    this.$Api.product.getAttrList2().then(result => {
      this.catalogs = this.catalogs.concat(result.Catalogs);
      result.Catalogs.forEach(element => {
        // this.catalogs = this.catalogs.concat(element.Children);
        this.$set(this.show, element.Id, false);
      });
      while (this.catalogs.length % 5 !== 0) {
        this.catalogs.push(new Catalogs(-1, '', '', '', -1, '', ''));
      }
    });
    window.addEventListener('click', (e) => {
      let target = e.target as HTMLElement;
      if (target.className !== 'showChildMore') {
        Object.keys(this.show).forEach(element => {
          this.show[element] = false;
        });
      }
    });
  }
  closeMenuGrandChids (item) {
    this.show[item.Id] = !this.show[item.Id];
    Object.keys(this.show).forEach(element => {
      if (element !== (item.Id + '')) this.show[element] = false;
    });
  }
  private To (item) {
    if (this.show[item.Id] !== undefined) this.show[item.Id] = false;
    this.$router.push('/product/cat/' + item.Id + '?name=' + item.Name);
  }
}
