



import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsRegAndPay extends Vue {
    private htmlString: string = '';
    created () {
      this.$Api.regAndPay.getHtml(this.id, 'c').then((result) => {
        this.htmlString = result;
      });
    }
    get id () {
      return this.$route.params.id;
    }
}
