



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Payment from '@/model/payment';
import PromotionDiscount from '@/model/PromotionDiscount';
@Component
export default class InsPayment extends Vue {
  data () {
    return {
      orderSure: false
    };
  }
  @Prop({ default: 0 }) private currentPrice!:number;
  @Prop({ default: 0 }) private realPrice!:number;
  @Prop() private readonly paymentMethods!:Payment[];
  private totalAmount:number = 0;
  @Prop() private readonly checkouting!:boolean;
  private radio: Payment = new Payment();
  private promotionCode :string = '';
  private showDistcount :boolean = false;
  @Watch('promotionCode')
  onPromotionCode () {
    if (!this.promotionCode) {
      this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
      this.$emit('promotionCode', '');
      this.showDistcount = false;
    }
  }
  @Watch('radio')
  onRadioChange (o, n) {
    this.$emit('paymentChange', this.radio);
  }
  @Watch('paymentMethods')
  onPaymentMethodsChange (o, n) {
    if (this.paymentMethods.length > 0) this.radio = this.paymentMethods[0];
  }
  created () {
    this.$store.state.shopCart.then((result) => {
      this.totalAmount = result.ShopCart.TotalAmount;
      // this.$store.state.dispatch('setCurrency', result.ShopCart.Currency);
    });
  }
  searchCoupon () {
    if (this.realPrice !== this.currentPrice) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.CandP')); return; }
    this.$Api.order.getPromotionCodeFrontView(this.promotionCode).then((result) => {
      this.$store.dispatch('setPromotionDiscount', result.PromotionDiscount);
      this.$emit('promotionCode', this.promotionCode);
      this.showDistcount = true;
    }).catch((error) => {
      console.log(this.$t('Message.Message'), error);
      this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.promotionCodeError'));
    });
  }
  get discount () {
    return this.$store.state.discount;
  }
  get currentCode () {
    return this.$store.state.currency.Code;
  }
  get cp () {
    return this.discount.Id === '-1'
      ? this.currentPrice
      : (this.discount.IsDiscount ? ((this.currentPrice) * (1 - this.discount.Favorable / 100)).toFixed(2) : (this.currentPrice) - this.discount.Favorable);
  }
}
