















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import sdk from '@/sdk/InstoreSdk';
import store from '../../../../store';
@Component({
  name: 'Login'
})
export default class InsLogin extends Vue {
  targetUrl: string = '';
  username: string = '';
  password: string = '';
  remember: boolean = false;
  lang:string[] = ['E', 'C', 'S'];
  // created () {
  //   this.$Confirm('info', 'error');
  // }
  checkEmail (rule, value, callback) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (!value) {
      callback(new Error(this.$t('Login.EnterEmail')as string));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('Login.CorrectEmail')as string));
      }
    }, 100);
  }
  // validatePassword (rule, value, callback: Function) {
  //   if (value === '') {
  //     callback(new Error(this.$t('Login.LoginPwd')as string));
  //   } else if (this.ruleForm.password !== '123456') {
  //     callback(new Error(this.$t('Login.LoginoldPwd')as string));
  //   } else {
  //     callback();
  //   }
  // }
       private ruleForm = {
         username: '',
         password: ''
       }

       get rules () {
         return {
           username: [{ required: true, validator: this.checkEmail, trigger: 'blur' }],
           // password: [{ validator: this.validatePassword, trigger: 'blur' }]
           password: [
             { required: true, message: this.$t('Login.LoginPwd'), trigger: 'blur' }
           ]

         };
       }
       private submitForm (formName) {
         let _this = this;
         (this.$refs.ruleForm as ElForm).validate((valid) => {
           if (valid) {
             sdk.api.member.login(this.ruleForm.username, this.ruleForm.password, true).then(
               function (response) {
                 _this.$store.dispatch('doLogin');
                 return _this.$route.query && _this.$route.query.returnurl ? _this.$route.query.returnurl : undefined;
                 //  if (_this.$route.query && _this.$route.query.returnurl) { _this.$router.replace(_this.$route.query.returnurl as string); } else { _this.$router.replace('/account/memberInfo'); }
                 //  _this.$router.push('/account/memberInfo');
                 //  _this.$message({
                 //    message: '登陆成功！',
                 //    type: 'success'
                 //  });
               },
               function (response) {
                 _this.$message({
                   message: response.Message,
                   type: 'error'
                 });
               }
             ).then(
               (url) => {
                 sdk.api.member.getProfile().then(
                   function (data) {
                     if (data) {
                       _this.ruleForm = data;
                       _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
                       _this.$i18n.locale = _this.lang[data.Language];
                       _this.$store.dispatch('setLang', _this.lang[data.Language]);
                       _this.$Storage.set('locale', _this.lang[data.Language]);
                       if (url) { _this.$router.push(_this.$route.query.returnurl as string); } else { _this.$router.push('/account/memberCentral'); }
                     } else {
                       _this.$store.dispatch('Logout');
                     }
                   },
                   function (data) {
                     _this.$message({
                       message: data,
                       type: 'error'
                     });
                   }
                 );
               }
             );
           } else {
             // this.$message.error('登录失败');
             console.log('error submit!!');
             return false;
           }
         });
       }
       onCancle () {
         this.$router.push('/account/register');
       }
}
