

























import { Vue, Prop, Component } from 'vue-property-decorator';
import InsButton from '@/components/base/mobile/InsButton.vue';
@Component({ components: { InsButton } })
export default class EdContactUs extends Vue {
  goToFacebook () {
    window.open('https://www.facebook.com/edtoys/');
  }
  goToInsTagram () {
    window.open('https://www.facebook.com/edtoys/');
  }
  goToPhone () {
    window.open('https://api.whatsapp.com/send?phone=85262891789&text=%E6%88%91%E6%83%B3%E6%9F%A5%E8%AF%A2%E4%B8%80%E4%B8%8BEDToys%E6%9C%8D%E5%8A%A1');
  }
}
