











import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import inProductList from '@/components/business/pc/product/InsProductList.vue';
import inPage from '@/components/base/pc/InsPage.vue';
import YouWouldLike from '@/model/youWouldLike';
import Currency from '@/model/currency';
@Component({ components: { inProductList, inPage } })
export default class Product extends Vue {
    @Prop() private readonly column!: number;
    private allItems: YouWouldLike[] = [new YouWouldLike('', '', '', '', '', '', new Currency(), true)];
    @Prop() private readonly pageNum!: number;
    private CurrentPage: number = 1;
    private TotalPage: number = 1;
    private TotalRecord: number = 0;
    get catId () {
      return this.$store.state.catId | this.$route.params.catId as any;
    }
    created () {
      this.$Api.product.getCatProduct(
        {
          CatId: this.catId,
          Page: this.CurrentPage,
          PageSize: this.pageNum
        }).then((result) => {
        this.allItems = result.YouWouldLike;
        this.TotalPage = result.TotalPage;
        this.TotalRecord = result.TotalRecord;
        this.$HiddenLayer();
      });
    }
    @Watch('CurrentPage')
    onCurrentPage () {
      this.$ShowLayer();
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
          this.$HiddenLayer();
        });
      }, 50);
    }
    @Watch('catId')
    onStoreSearchKey () {
      this.CurrentPage = 1;
      this.$ShowLayer();
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$Api.product.getCatProduct(
          {
            CatId: this.catId,
            Page: this.CurrentPage,
            PageSize: this.pageNum
          }).then((result) => {
          this.allItems = result.YouWouldLike;
          this.TotalPage = result.TotalPage;
          this.TotalRecord = result.TotalRecord;
          this.$HiddenLayer();
        });
      }, 50);
    }
}
