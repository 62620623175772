import { render, staticRenderFns } from "./InsOrderComplete.vue?vue&type=template&id=ce5f41cc&scoped=true&"
import script from "./InsOrderComplete.vue?vue&type=script&lang=ts&"
export * from "./InsOrderComplete.vue?vue&type=script&lang=ts&"
import style0 from "./InsOrderComplete.vue?vue&type=style&index=0&id=ce5f41cc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce5f41cc",
  null
  
)

export default component.exports