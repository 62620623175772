
















import { Component, Prop, Vue } from 'vue-property-decorator';
import Express from '@/model/express';
@Component
export default class InsSubTotal extends Vue {
  // @Prop() Express!: Express;
  ItemsAmount: number = 0;
  created () {
    this.$store.state.shopCart.then((result) => {
      this.ItemsAmount = result.ShopCart.ItemsAmount;
    });
  }
  get currentCode () {
    return this.$store.state.currency.Code;
  }
}
