
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Shoppingcart from '@/views/mobile/InsShoppingcart.vue';
import Fare from '@/components/business/mobile/account/InsFare.vue';
import Fare2 from '@/components/business/mobile/account/InsFare2.vue';
import Payment from '@/components/business/mobile/account/InsPayment.vue';
import Concessions from '@/components/business/mobile/account/InsConcessions.vue';
import OrderPreview from '@/components/business/mobile/account/InsOrderPreview.vue';
import Complete from '@/components/business/mobile/account/InsCompleteCheckout.vue';
import Button from '@/components/base/mobile/InsButton.vue';
import { CreateOrder } from '@/model/order';
import Express from '@/model/express';
import PickupAddress from '@/model/pickupAddress';
import PaymentMethod from '@/model/payment';
import PromotionDiscount from '@/model/PromotionDiscount';
@Component({ components: { Shoppingcart, Fare, Fare2, Concessions, Payment, OrderPreview, Complete, Button } })
export default class InsCheckout extends Vue {
    private readonly steps:string[] = [];
    private active:number = 0;
    private componentz:string[] = ['Shoppingcart', 'Fare2', 'Concessions', 'Payment', 'OrderPreview', 'Complete'];
    private orderId:number = 0;
    private lockedNext:boolean = false;
    private express:boolean = false;
    private PickAddress: boolean = false;
    private payments:PaymentMethod[] = [];
    private loading: boolean = false;
    private promotionCode:string = '';
    private coupons:string[] = [];
    get currentComponent () {
      return this.componentz[this.active];
    }
    next () {
      this.loading = true;
      this.active = 1;
      if (this.$store.state.DeliveryType === 'D' && !this.express && this.componentz[this.active] === 'Fare2') { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); this.loading = false; return; }
      if (this.$store.state.DeliveryType === 'P' && !this.PickAddress && this.componentz[this.active] === 'Fare2') { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.PickAddressError')); this.loading = false; return; } else if (this.$store.state.DeliveryType === 'P' && this.PickAddress && this.componentz[this.active] === 'Fare2') {
        this.$Api.delivery.savePickupInfo(this.$store.state.pickupAddress.Id, this.$store.state.pickupAddress.CompanyAddress);
      }
      this.active = 3;
      if (this.componentz[this.active] === 'Payment') {
        this.$Api.paymentApi.savePayMethod(this.payments[this.$store.state.paymentIndex].Id).then((result) => {
          this.$store.dispatch('setPaymentMethod', this.payments[this.$store.state.paymentIndex]);
        }).then(() => { this.pay(); });
      }
      // this.active = 4;
      // if (this.componentz[this.active] === 'OrderPreview') { this.pay(); }
      // this.active++;
    }
    before () {
      this.active--;
    }
    changeFare () {
      this.componentz[1] = this.componentz[1] === 'Fare' ? 'Fare2' : 'Fare'; this.$message(this.$t('Message.SucceedInOperating') as string);
    }
    created () {
      this.steps.push(this.$t('CheckOut.ConfirmationOfOrder') as string);
      this.steps.push(this.$t('CheckOut.ShippingMethod') as string);
      this.steps.push(this.$t('CheckOut.Concessions') as string);
      this.steps.push(this.$t('CheckOut.PaymentMethod') as string);
      this.steps.push(this.$t('CheckOut.OrderPreview') as string);
      this.steps.push(this.$t('CheckOut.Complete') as string);
      this.$store.dispatch('setAddress', this.$Api.checkout.getAddress2());
      this.$store.dispatch('setPaymentMethods', this.$Api.checkout.getPaymentMethod());
    }
    pay () {
      let order;
      let profile = this.$store.state.memberInfo;
      console.log(this.$store.state.DeliveryType);
      if (this.$store.state.DeliveryType === 'D') order = new CreateOrder(this.$store.state.pickUpExpress ? -1 : this.$store.state.selectAddress.DeliveryId, this.$store.state.express.Id, this.payment.Id, 'D', '', '', profile.Mobile, profile.FirstName + profile.LastName, this.promotionCode, this.$store.state.expressPoint.Id, this.coupons);
      else if (this.$store.state.DeliveryType === 'P') order = new CreateOrder(this.$store.state.pickupAddress.Id, '', this.payment.Id, 'P', this.$store.state.pickupAddress.PickupDate, this.$store.state.pickupAddress.PickupTime, this.$store.state.pickupAddress.Phone, this.$store.state.pickupAddress.Name, this.promotionCode, '', this.coupons);
      this.$store.dispatch('setDeliveryType', 'D');
      this.$store.dispatch('setExpress', new Express());
      this.$store.dispatch('setPickupAddress', new PickupAddress());
      this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
      this.$Api.order.createOrder(
        order
      ).then((result) => {
        if (result.Succeeded) { this.$store.dispatch('setPromotionDiscount', new PromotionDiscount()); this.orderId = result.ReturnValue; this.$router.push('/account/complete/' + result.ReturnValue); } else { Vue.prototype.$Confirm('error', result.Message); this.loading = false; }
      });
    }
    get payment () {
      return this.$store.state.paymentMethod;
    }
    get pm () {
      return this.$store.state.paymentMethods;
    }
    setPromotionCode (code) {
      this.promotionCode = code;
    }
    setCoupons (coupons) {
      this.coupons = coupons;
    }
}
