import { render, staticRenderFns } from "./InsComments.vue?vue&type=template&id=5688dd63&scoped=true&"
import script from "./InsComments.vue?vue&type=script&lang=ts&"
export * from "./InsComments.vue?vue&type=script&lang=ts&"
import style0 from "./InsComments.vue?vue&type=style&index=0&id=5688dd63&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5688dd63",
  null
  
)

export default component.exports