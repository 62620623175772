




















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ExpressAndOutlets from '@/model/ExpressAndOutlets';
import ExpressPoint from '@/model/ExpressPoint';
import Address from '@/model/address';
import { MemberResult } from '@/model/memberResult';
import { Country } from '@/model/country';
import { Province } from '@/model/province';
import { Form } from 'element-ui';
import PickupAddress from '@/model/pickupAddress';
class ExpressChargeReq {
  ItemAmount!:number;
  PointId!:string;
  constructor (ItemAmount:number, PointId:string) {
    this.ItemAmount = ItemAmount;
    this.PointId = PointId;
  }
}
@Component
export default class InsFare2 extends Vue {
    private Express:ExpressAndOutlets[] = [];
    private ChosenExpress:ExpressAndOutlets = new ExpressAndOutlets();
    private ChosenExpressPoint:ExpressPoint = new ExpressPoint();
    private Profile = new MemberResult();
    private SelectedAddress:Address = new Address();
    private editAddress:Address = new Address();
    private addressList:Address[] = [];
    private TotalWeight:number = 0;
    private ItemsAmount:number = 0
    private dialogVisible = false;
    private countryList: Country[] = [];
    private provinceList: Province[] = [];
    private allProvice: any = {};
    private expressError: boolean = false;
    private chooseCharge: boolean = false;
    private chooseChargeList: ExpressAndOutlets[] = [];
    private ChosenExpressCharge: ExpressAndOutlets = new ExpressAndOutlets();
    private loading: boolean = true;
    private PickAddress: PickupAddress = new PickupAddress();
    private CurrentPickupAddress: PickupAddress = new PickupAddress();
    private PickupAddressList: PickupAddress[] = [];
    @Prop({ default: false }) private lockFare!: boolean;
    created () {
      if (this.lockFare) { this.loading = false; return; }
      this.$store.dispatch('setPickUpExpress', true);
      let profile = this.$Api.member.getProfile2().then((result) => {
        this.Profile = result.MemberResult;
        this.$store.dispatch('setMemberInfo', result.MemberResult);
        this.PickAddress.Name = this.Profile.FirstName + ' ' + this.Profile.LastName;
        this.PickAddress.Phone = this.Profile.Mobile;
      });
      let express = this.$Api.delivery.getExpressAndOutlets().then((result) => {
        this.Express = result.ExpressAndOutlets;
        this.ChosenExpress = result.ExpressAndOutlets.length > 0 ? result.ExpressAndOutlets[0] : new ExpressAndOutlets();
        this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint();
      });
      let shopcart = this.$store.state.shopCart.then((result) => {
        this.TotalWeight = result.ShopCart.TotalWeight;
        this.ItemsAmount = result.ShopCart.ItemsAmount;
      });
    }
    @Watch('PickAddress', { deep: true })
    onPickAddressChange () {
      this.Shake(() => {
        if (!this.PickAddress.Phone || !this.PickAddress.Name || !this.PickAddress.PD) { this.$emit('PickAddressError'); this.$message(this.$t('CheckOut.PickAddressError') as string); } else {
          this.$emit('PickAddress');
          this.$store.dispatch('setPickupAddress', this.PickAddress);
        }
      }, 500);
    }
    @Watch('ChosenExpress')
    onChosenExpressChange () {
      this.loading = true;
      this.chooseCharge = false;
      this.$store.dispatch('setPickUpExpress', this.ChosenExpress.IsExpressPoint);
      if (!this.$store.state.pickUpExpress) {
        this.ChosenExpressPoint = new ExpressPoint('', '', '-1');
        this.$store.dispatch('setDeliveryType', 'D');
        this.$Api.delivery.getDefaultAddrForEx(this.ChosenExpress.Id).then((result) => {
          this.SelectedAddress = result.Address;
          if (this.SelectedAddress.DeliveryId) this.$store.dispatch('setSelectAddress', this.SelectedAddress);
          else throw new Error('no default address');
        }).catch(async (e) => {
          // 这里应该是要处理没有默认地址的，获取当前快递支持的用户地址的第一个
          await this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
            this.SelectedAddress = result.Address.length > 0 ? result.Address[0] : new Address();
            this.$store.dispatch('setSelectAddress', this.SelectedAddress);
          });
        }).then(() => {
          this.Shake(this.getExpressChargeForEx);
          this.$emit('express'); this.expressError = true;
        });
      } else {
        if (this.ChosenExpress.ExpressCompanyId !== 'P') {
          this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint('', '', '-1');
          this.$store.dispatch('setDeliveryType', 'D');
          this.$store.dispatch('setExpress', this.ChosenExpress);
          if (this.ChosenExpress.ExpressPointList.length === 0) { this.$emit('expressError'); this.expressError = false; this.loading = false; } else { this.$emit('express'); this.expressError = true; }
        } else if (this.ChosenExpress.ExpressCompanyId === 'P') {
          this.ChosenExpressPoint = new ExpressPoint();
          this.$store.dispatch('setDeliveryType', 'P');
          this.$Api.delivery.getPickupAddressV2().then((result) => {
            this.$emit('express');
            this.loading = false;
            this.PickupAddressList = result.PickupAddress;
            if (this.PickupAddressList.length > 0) this.CurrentPickupAddress = this.PickupAddressList[0]; else this.CurrentPickupAddress = new PickupAddress();
            this.PickAddress.Id = this.CurrentPickupAddress.Id;
            this.PickAddress.CompanyAddress = this.CurrentPickupAddress.Address;
          });
        }
        // this.$store.dispatch('setExpressPoint', this.ChosenExpressPoint);
      }
    }
    @Watch('ChosenExpressPoint')
    onChosenExpressPoint () {
      if (this.ChosenExpressPoint.Id === '-1') return;
      this.loading = true;
      this.$Api.delivery.getExpressPointCharge(new ExpressChargeReq(this.ItemsAmount, this.ChosenExpressPoint.Id)).then(
        (result) => {
          this.ChosenExpressPoint.DiscountPrice = result;
          this.$emit('express');
          this.$store.dispatch('setExpressPoint', this.ChosenExpressPoint);
          this.loading = false;
        }
      );
    }
    @Watch('editAddress.Country')
    onCountryChange () {
      // this.provinceList = this.allProvice[this.editAddress.Country.Id];
      // if (!Array.isArray(this.provinceList)) {
      this.$Api.delivery.getProvinceForEx(this.ChosenExpress.Id, this.editAddress.Country.Id).then((result) => {
        console.log(result);
        this.provinceList = result.Province;
        // this.allProvice[this.editAddress.Country.Id] = result.Province;
      });
      // }
    }
    @Watch('SelectedAddress')
    onSelectedAddressChange () {
      this.Shake(this.getExpressChargeForEx);
    }
    getExpressChargeForEx () {
      if (this.SelectedAddress.DeliveryId === 0) { this.$Confirm(this.$t('Message.Message'), this.$t('DeliveryAddress.ChooseDeliveryAddress')); this.$emit('expressError'); this.expressError = false; this.loading = false; return; };
      this.$Api.delivery.getExpressChargeForEx({
        DeliveryAddrId: this.SelectedAddress.DeliveryId,
        TotalWeight: this.TotalWeight,
        ItemAmount: this.ItemsAmount,
        ExpressId: this.ChosenExpress.Id }).then((result) => {
        this.loading = false;
        if (result.ExpressAndOutlets.length === 0) {
          this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); this.$emit('expressError'); this.expressError = false;
        } else if (result.ExpressAndOutlets.length === 1) {
          this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]);
          this.$emit('express');
          this.expressError = true;
        } else if (result.ExpressAndOutlets.length > 1) {
          this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]);
          this.$emit('express');
          this.expressError = true;
          this.chooseCharge = true;
          this.chooseChargeList = result.ExpressAndOutlets;
          this.ChosenExpressCharge = result.ExpressAndOutlets[0];
        } else { this.$emit('expressError', this.$t('CheckOut.expressError')); this.expressError = false; }
      });
    }
    @Watch('ChosenExpressCharge')
    onChoseExpressChargeChange () {
      this.$store.dispatch('setExpress', this.ChosenExpressCharge);
    }
    get validateAddress () {
      return {
        FirstName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserName'),
            trigger: 'blur'
          }
        ],
        LastName: [
          {
            required: true,
            message: this.$t('MemberInfo.EnterUserLastName'),
            trigger: 'blur'
          }
        ],
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('MemberInfo.EnterUserPhone') as string));
              } else if (Number.isNaN(Number(value))) {
                callback(new Error(this.$t('MemberInfo.EnterNumber') as string));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        Mobile: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('MemberInfo.EnterUserPhone') as string));
              } else if (Number.isNaN(Number(value))) {
                callback(new Error(this.$t('MemberInfo.EnterNumber') as string));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        Country: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.Id === '') {
                callback(new Error(this.$t('Address.Country') as string));
              } else {
                callback();
              }
            },
            // required: true,
            // message: this.$t('Address.Country'),
            trigger: ['blur']
          }
        ],
        Provinceo: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || value.Id === '' || value.Id === 0) {
                callback(new Error(this.$t('Address.Province') as string));
              } else {
                callback();
              }
            },
            // required: true,
            // message: this.$t('Address.Province'),
            trigger: ['blur']
          }
        ],
        Address: [
          {
            required: true,
            message: this.$t('Address.Address'),
            trigger: 'blur'
          }
        ]
      };
    }
    editAddr (index) {
      this.editAddress = this.addressList[index];
    }
    removeAddr (index) {
      if (this.addressList[index] && this.addressList[index].DeliveryId === this.SelectedAddress.DeliveryId) {
        // this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.AddressLock')); return;
        this.SelectedAddress = new Address();
        this.$store.dispatch('setSelectAddress', this.SelectedAddress);
      }
      let address = this.addressList.splice(index, 1);
      if (address.length) { this.$Api.address.removeAddress(address[0].DeliveryId); }
    }
    selectAddr (index) {
      this.loading = true;
      this.SelectedAddress = this.addressList[index];
      this.$store.dispatch('setSelectAddress', this.SelectedAddress);
      this.dialogVisible = false;
      this.$message(this.$t('Message.SucceedInOperating') as string);
    }
    loadAddress () {
      // if (!this.expressError) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); return; }
      this.dialogVisible = true;
      this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
        this.addressList = result.Address;
      });
      this.$Api.delivery.getCountyForEx(this.ChosenExpress.Id).then((result) => {
        console.log(result);
        this.countryList = result.Country;
      });
    }
    save (formName) {
      let form = {
        Province: this.editAddress.Provinceo.Id,
        CountryId: this.editAddress.Country.Id,
        ProvinceName: this.editAddress.Provinceo.Name,
        DeliveryId: this.editAddress.DeliveryId === 0 ? undefined : this.editAddress.DeliveryId,
        Default: this.editAddress.Default,
        IsUsable: true,
        FirstName: this.editAddress.FirstName,
        LastName: this.editAddress.LastName,
        Phone: this.editAddress.Phone,
        Mobile: this.editAddress.Mobile,
        PostalCode: this.editAddress.PostalCode,
        Address: this.editAddress.Address
      };
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          this.$Api.address.saveAddress(form).then((result) => {
          }).then(() => {
            this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
              this.SelectedAddress = result.Address.length > 0 ? result.Address[0] : new Address();
              this.$store.dispatch('setSelectAddress', this.SelectedAddress);
              this.$message(this.$t('Message.SucceedInOperating') as string);
              this.dialogVisible = false;
            });
            // this.$Api.checkout.getAddress2().then((result) => {
            //   this.addressList = result.Address;
            //   this.dialogVisible = false;
            //   this.$message(this.$t('Message.SucceedInOperating') as string);
            // });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
}
