






























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Commodity from '@/components/business/pc/account/InsCommodity.vue';
import Fare from '@/components/business/pc/account/InsFare2.vue';
import Subtotal from '@/components/business/pc/account/InsSubTotal.vue';
import Payment from '@/components/business/pc/account/InsPayment.vue';
import Addresspopup from '@/components/business/pc/account/InsAddressPopUp.vue';
import ShopCart from '@/model/ShopCart';
import Express from '@/model/express';
import Address from '@/model/address';
import PaymentM from '@/model/payment';
import Order, { CreateOrder } from '@/model/order';
import { Country } from '@/model/country';
import PickupAddress from '@/model/pickupAddress';
import PromotionDiscount from '@/model/PromotionDiscount';
import Coupon from '@/model/coupon';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    Commodity,
    Fare,
    Subtotal,
    Payment,
    Addresspopup
  }
})
export default class InsCheckout extends Vue {
  data () {
    return {
      prodcutSrc: require('@/assets/Images/270_b.jpg'),
      step: 1,
      totalAmount: 0,
      itemsAmount: 0,
      ItemsTaxAmount: 0,
      Currency: {},
      MaxQty: 20,
      cartItems: [],
      currentCode: '',
      deliveryType_s: 'D',
      items: [
        {
          Id: 1,
          AttrTypeName1: '尺码',
          AttrName1: '中',
          AttrTypeName2: '颜色',
          AttrName2: '黑色',
          AttrTypeName3: '大小',
          AttrName3: '小码',
          Qty: 2,
          Product: {
            Img_M: require('@/assets/Images/270_b.jpg'),
            Name: '安踏T',
            Code: '12346',
            SalePrice: 245,
            Sku: '/product/list',
            Currency: {
              Code: 'HKD',
              Desc: null,
              Id: 1,
              Name: 'HKD'
            }
          }
        }
      ],
      orderSure: false,
      selectAddress: '',
      selectPickupAddress: '',
      selectExpressOne: 0,
      countryList: [
        {
          Name: '中國香港'
        }
      ],
      provinceList: [
        {
          Name: '中國香港'
        }
      ],
      expressList: [
        {
          currentCode: 'HKD',
          CountryCode: null,
          Discount: 50,
          DiscountPrice: 0,
          ExpressCompanyId: '1046',
          ExpressCompanyName: '郵費（香港）',
          ExpressPointId: '0',
          ExpressPointList: null,
          Id: '1046',
          IsExpressPoint: false,
          IsSelfDefineDeliveryDate: true,
          Price: 50,
          ServiceType: null,
          value: '选项1',
          label: {
            ExpressCompanyName: '郵費（香港）',
            currentCode: 'HKD',
            ExpressPointId: '0'
          }
        }
      ]
    };
  }
  private payments:PaymentM[] = [new PaymentM()];
  private Country:Country[] = [];
  // private express:Express = new Express();
  private payment!:PaymentM;
  private checkouting = true;

  private express:boolean = false;
  private PickAddress: boolean = false;
  private orderId:number = 0;
  private promotionCode:string = '';

  private coupon:Coupon[] = [];
  private delete1: any = [];
  private selectedCoupon:Coupon[] = [];
  private multiple:Coupon[] = [];
  private single:Coupon[] = [];
  private all = {};
  private totalAmount:number = 0;
  private currentPrice: number = 0;
  private ll: boolean = false;
  private exp: number = 0;
  Hyperlink () {
    // window.location.href = '/account/shoppingcart';
    this.$router.push('/account/shoppingcart');
  }
  pay () {
    let order;
    let profile = this.$store.state.memberInfo;
    if (this.$store.state.DeliveryType === 'D') order = new CreateOrder(this.$store.state.pickUpExpress ? -1 : this.$store.state.selectAddress.DeliveryId, this.$store.state.express.Id, this.payment.Id, 'D', '', '', profile.Mobile, profile.FirstName + profile.LastName, this.promotionCode, this.$store.state.expressPoint.Id, this.delete1);
    else if (this.$store.state.DeliveryType === 'P') order = new CreateOrder(this.$store.state.pickupAddress.Id, '', this.payment.Id, 'P', this.$store.state.pickupAddress.PickupDate, this.$store.state.pickupAddress.PickupTime, this.$store.state.pickupAddress.Phone, this.$store.state.pickupAddress.Name, this.promotionCode, '', this.delete1);
    this.$Api.order.createOrder(
      order
    ).then((result) => {
      if (result.Succeeded) {
        this.orderId = result.ReturnValue;
        this.$store.dispatch('setDeliveryType', 'D');
        this.$store.dispatch('setExpress', new Express());
        this.$store.dispatch('setPickupAddress', new PickupAddress());
        this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
        this.$router.push({ name: 'completeCheckout', params: { id: result.ReturnValue } });
      } else { Vue.prototype.$Confirm('error', result.Message); }
    });
  }
  checkOut () {
    if (this.$store.state.DeliveryType === 'D' && !this.express) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); return; }
    if (this.$store.state.DeliveryType === 'P' && !this.PickAddress) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.PickAddressError')); return; } else if (this.$store.state.DeliveryType === 'P' && this.PickAddress) {
      this.$Api.delivery.savePickupInfo(this.$store.state.pickupAddress.Id, this.$store.state.pickupAddress.CompanyAddress);
    }
    if (this.payment !== undefined) {
      this.$Api.paymentApi.savePayMethod(this.payment.Id).then((result) => {
        this.checkouting = false;
        this.$store.dispatch('setPaymentMethod', this.payment);
      });
    } else {
      this.$Confirm('请填写完整信息', '支付方式尚未选择！');
    }
  }
  created () {
    this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
    this.$store.dispatch('setAddress', this.$Api.checkout.getAddress2());
    let pm = this.$Api.checkout.getPaymentMethod().then(result => {
      this.payments = result.Payment;
    });
    Promise.all([this.$store.state.shopCart, this.$store.state.address, pm]).then((result) => {
      let sc = result[0];
      this.totalAmount = sc.ShopCart.TotalAmount;
      this.currentPrice = this.totalP;
      this.$HiddenLayer();
      this.$Api.member.getActiveCoupon({ Page: 1, PageSize: 10 }).then((result) => {
        this.coupon = result.Coupon;
        this.coupon.forEach((element) => {
          if (element.MeetAmount >= this.totalP) return;
          element.canCheck = false;
          this.$set(this.all, element.Id, element);
          if (element.IsAdditional === true) this.multiple.push(element);
          else this.single.push(element);
        });
      });
    });
  }
  setPromotionCode (code) {
    this.promotionCode = code;
  }
  checkChange (item) {
    let canPush = true;
    this.selectedCoupon.forEach((e, index) => {
      if (e.Id === item.Id) {
        canPush = false;
        this.selectedCoupon.splice(index, 1);
      }
    });
    if (canPush) this.selectedCoupon.push(item);
  }
  @Watch('delete1', { deep: true })
  onSelectedChange (n, old) {
    if (n.length === 0) {
      this.single.forEach(element => {
        element.canCheck = false;
      });
      this.multiple.forEach(element => {
        element.canCheck = false;
      });
      if (this.ll) { this.ll = false; return; }
    }
    let current = this.delete1[this.delete1.length - 1];
    if (old.length === 0 && n.length === 1) {
      if (this.all[current].IsAdditional === true) {
        this.single.forEach(element => {
          if (this.all[current].Id !== element.Id) { element.canCheck = true; }
        });
      } else {
        this.single.forEach(element => {
          if (this.all[current].Id !== element.Id) { element.canCheck = true; }
        });
        this.multiple.forEach(element => {
          element.canCheck = true;
        });
      }
    }
    // 計算多個優惠
    let mult:any[] = [];
    let temp = this.totalP;
    this.currentPrice = this.totalP;
    this.delete1.forEach(element => {
      if (this.all[element].IsDiscount) mult.push(this.all[element]);
      else {
        temp = this.currentPrice - this.all[element].DiscountAmount;
      }
    });
    mult.forEach((element) => {
      temp -= this.currentPrice * (element.DiscountAmount / 100);
    });
    this.currentPrice = temp;
  }
  get totalP () {
    this.currentPrice = this.totalAmount;
    return this.totalAmount;
  }
  get exdp () {
    this.exp = this.$store.state.express.DiscountPrice;
    return Number(this.$store.state.express.DiscountPrice as string);
  }
  @Watch('exp')
  onExpChange () {
    // this.ll = true;
    if (this.delete1.length !== 0) { this.delete1 = []; };
    if (this.selectedCoupon.length !== 0) { this.selectedCoupon = []; };
  }
}
